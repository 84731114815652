import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExpirationDateContext from './ExpirationDateContext';

const formatPhoneNumber = (number) => {
  return number.replace(/\D/g, "");
};

const PairButton = ({ location }) => {
  const { claim } = location.state;
  const [suggestedPairs, setSuggestedPairs] = useState([]);
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    customerName: "",
    trans: "",
    location: "",
  });
  const { updateExpirationDate } = useContext(ExpirationDateContext);

  const [searchResults, setSearchResults] = useState([]);
  const [searchMessage, setSearchMessage] = useState("");
  const [modal, setModal] = useState(false); // Modal state
  const [isSearching, setIsSearching] = useState(false);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date, field) => {
    setFormData({ ...formData, [field]: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.startDate &&
      !formData.endDate &&
      !formData.customerName &&
      !formData.trans &&
      !formData.location
    ) {
      setSearchResults([]);
      setSearchMessage("Please provide at least one search filter.");
      return;
    }
    setIsSearching(true); // Show spinner


    try {
      const formattedStartDate = formData.startDate ? formData.startDate.toISOString().split('T')[0] : '';
      const formattedEndDate = formData.endDate ? formData.endDate.toISOString().split('T')[0] : '';

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/search_warranties/`, {
        params: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          customerName: formData.customerName,
          trans: formData.trans,
          location: formData.location
        },
      });

      if (response.data.length === 0) {
        setSearchResults([]);
        setSearchMessage("This customer does not have any warranty.");
      } else {
        setSearchResults(response.data);
        setSearchMessage("");
      }
    } catch (error) {
      console.error("Error:", error);
      setSearchResults([]);
      setSearchMessage("This customer does not have any warranty.");
    } finally {
      setIsSearching(false); // Hide spinner
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // we are sending the data for the search filter by 5 params 
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/suggested_pairs/`, {
          full_name: claim.full_name,
          address: `${claim.address}, ${claim.city}, ${claim.state}, ${claim.zipcode}`,
          //Extra work for the service claim website
          address2: claim.address2,
          email: claim.email,
          phone_number: formatPhoneNumber(claim.phone_number),
          transaction_number: claim.transaction_number,
          // model_number: claim.model_number,
        });
        setSuggestedPairs(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [claim.full_name, claim.address,claim.address2, claim.city, claim.state, claim.zipcode, claim.email, claim.model_number, claim.phone_number, claim.transaction_number]);
  




// const handlePairButtonClick = async (pairData, isFromSuggestedPairs = false) => {
//   console.log('Pair Data:', pairData);
//   try {
//     const formattedPurchaseDate = pairData.s_date_sold ? pairData.s_date_sold.split('T')[0] : '';
//     const storeNumber = pairData.store_number || null;

//     // Data to send to pair_claim API
//     const pairClaimData = {
//       brand: pairData.brand,
//       transaction_number: pairData.transaction_number,
//       store: storeNumber,
//       purchase_date: formattedPurchaseDate,
//       model_number: pairData.model_number,
//       price_product: pairData.product_price,
//       warranty_price: pairData.warranty_price,
//       product_price: pairData.product_price,
//       department_id: pairData.department_id,
//       warranty_transaction_number: pairData.warranty_transaction_number,
//       email: pairData.email,
//       phone_number: pairData.phone_number,
//       store_number: storeNumber,
//       warranty_expiry_date: pairData.warranty_expiry_date,
//       full_name: pairData.name,
//       address: pairData.address,
//       warranty_type: pairData.warranty_type,
//     };

//     console.log('Pair Claim Data:', pairClaimData);

//     // Send data to pair_claim API
//     const pairClaimResponse = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/pair_claim/${claim.id}/`, pairClaimData);
//     console.log('Pairing successful:', pairClaimResponse.data);

//     if (isFromSuggestedPairs) {
//       // Data to send to create_warranty_product_pair API (only for suggested pairs)
//       const createWarrantyProductPairData = {
//         trans: pairData.transaction_number,
//         date_sold: formattedPurchaseDate,
//         store: storeNumber,
//         warranty_id: pairData.warranty_type,
//         product_retail_price: pairData.product_price || 0,
//         brand: pairData.brand,
//         product_cost: pairData.product_price || 0,
//         product_sku: pairData.model_number,
//         product_qty: pairData.Quantity || 1,
//       };

//       console.log('Create Warranty Product Pair Data:', createWarrantyProductPairData);

//       // Send data to create_warranty_product_pair API
//       const createWarrantyProductPairResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create_warranty_product_pair/`, createWarrantyProductPairData);
//       console.log('Warranty Product Pair creation successful:', createWarrantyProductPairResponse.data);
//     }

//     updateExpirationDate(claim.id, pairData.warranty_expiry_date);

//     setModal(true); // Open modal
//   } catch (error) {
//     console.error('Error pairing:', error);
//     if (error.response) {
//       console.error('Server response:', error.response.data);
//       alert(`Error: ${error.response.data.error || 'Unknown error occurred'}`);
//     } else {
//       alert('An error occurred while pairing the claim. Please try again.');
//     }
//   }
// };




const handlePairButtonClick = async (pairData, isFromSuggestedPairs = false) => {
  console.log('Pair Data:', pairData);
  try {
    const formattedPurchaseDate = pairData.s_date_sold ? pairData.s_date_sold.split('T')[0] : '';
    const storeNumber = pairData.store_number || null;

    // Construct the pairClaimData object without the address2 field
    const pairClaimData = {
      brand: pairData.brand || '',
      transaction_number: pairData.transaction_number || '',
      store: storeNumber || 0,
      purchase_date: formattedPurchaseDate || '',
      model_number: pairData.model_number || '',
      price_product: pairData.product_price || 0.0,
      warranty_price: pairData.warranty_price || 0.0,
      product_price: pairData.product_price || 0.0,
      department_id: pairData.department_id || 0,
      warranty_transaction_number: pairData.warranty_transaction_number || '',
      email: pairData.email || '',
      phone_number: pairData.phone_number || '',
      store_number: storeNumber || 0,
      warranty_expiry_date: pairData.warranty_expiry_date || '',
      full_name: pairData.name || '',
      address: pairData.address || '',
      warranty_type: pairData.warranty_type || '',
    };

    console.log('Pair Claim Data:', pairClaimData);

    const pairClaimResponse = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/pair_claim/${claim.id}/`, pairClaimData);
    console.log('Pairing successful:', pairClaimResponse.data);

    // If address2 is not present, make the call to create_warranty_product_pair
    if (isFromSuggestedPairs && !claim.address2) {
      const createWarrantyProductPairData = {
        trans: pairData.transaction_number || '',
        date_sold: formattedPurchaseDate || '',
        store: storeNumber || 0,
        warranty_id: pairData.warranty_type || '',
        product_retail_price: parseFloat(pairData.product_price) || 0.0,
        brand: pairData.brand || '',
        product_cost: parseFloat(pairData.product_price) || 0.0,
        product_sku: pairData.model_number || '',
        product_qty: pairData.Quantity || 0,
      };

      console.log('Create Warranty Product Pair Data:', createWarrantyProductPairData);

      const createWarrantyProductPairResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create_warranty_product_pair/`, createWarrantyProductPairData);
      console.log('Warranty Product Pair creation successful:', createWarrantyProductPairResponse.data);
    }

    updateExpirationDate(claim.id, pairData.warranty_expiry_date);

    setModal(true);
  } catch (error) {
    console.error('Error pairing:', error);
    if (error.response) {
      console.error('Server response:', error.response.data);
      alert(`Error: ${error.response.data.error || 'Unknown error occurred'}`);
    } else {
      alert('An error occurred while pairing the claim. Please try again.');
    }
  }
};




const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000).toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' });
};

const toUpperCase = (text) => {
  return text ? text.toUpperCase() : '';
};




  const toggleModal = () => setModal(!modal);

  return (
    <div className="container-fluid mt-4">
      <h2 style={{ textAlign: 'center' }}>Let's pair a warranty</h2>

      {/* Modal for Success Message */}
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Success!</ModalHeader>
        <ModalBody className="text-center">
          <div style={{ fontSize: '50px', marginBottom: '20px' }}>
            {/* Blue tick */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#3498db"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-check-circle"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
              <polyline points="22 4 12 14.01 9 11.01"></polyline>
            </svg>
          </div>
          <h4>Hey, you have successfully paired a claim!</h4>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>OK</Button>
        </ModalFooter>
      </Modal>

      {/* Claim details table */}
      <div style={{ margin: '0 -15px' }}>
        <table className="table table-bordered" style={{ background: "#f9f9f9" }}>
          <thead style={{ backgroundColor: "#343a40", color: "white" }}>
            <tr>
              <th>Date Submitted</th>
              <th>Name</th>
              <th>Address</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Transaction Number</th>
              <th>Brand</th>
              <th>Dept</th>
              <th>Model Number</th>
              <th>Model Desc</th>
              <th>Warranty Price</th>
              <th>Customer Desc</th>
              <th>Warranty ID</th>
              <th>Service Claim</th>
              <th>Status</th>
              

            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{claim.created_date_only}</td>
              <td>{claim.full_name}</td>
              <td>{`${claim.address}, ${claim.city}, ${claim.state}, ${claim.zipcode}`}</td>
              <td>{claim.email}</td>
              <td>{formatPhoneNumber(claim.phone_number)}</td>
              <td>{claim.transaction_number}</td>
              <td>{toUpperCase(claim.brand)}</td>
              <td>{claim.department_id}</td>
              <td>{toUpperCase(claim.model_number)}</td>
              <td>{claim.model_description}</td>
              <td>{claim.price_warranty}</td>
              <td>{claim.customer_description}</td>
              <td>{claim.warranty_id}</td>
              <td>{claim.address2 ? 'Yes' : 'No'}</td>
              <td>{claim.internal_status}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Suggested pairs table */}
      <h2 className="text-center mt-4" style={{ color: "#343a40" }}>Suggested Pairs</h2>
      <div style={{ margin: '0 -15px' }}>
        <table className="table table-bordered w-100" style={{ background: "#f9f9f9" }}>
          <thead style={{ backgroundColor: "#343a40", color: "white" }}>
            <tr>
              <th>Warranty Sell Date </th>
              <th>Product Sell Date </th>
              <th>Name</th>
              <th>Address</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Transaction Number</th>
              <th>Brand</th>
              <th>Model Number</th>
              <th>QTY</th>
              <th>Warranty Type</th>
              <th>Warranty Expiration</th>
              <th>Warranty Price</th>
              <th>Product Price</th>
              <th>Store Location</th>
              <th>Pair</th>
            </tr>
          </thead>
          <tbody>
            {suggestedPairs.map((pair, index) => (
              <tr key={index}>
                <td>{formatDate(pair.date_sale)}</td>
                <td>{formatDate(pair.s_date_sold)}</td>
                <td>{pair.name}</td>
                <td>{pair.address}</td>
                <td>{pair.email}</td>
                <td>{pair.phone_number}</td>
                <td>{pair.transaction_number}</td>
                <td>{toUpperCase(pair.brand)}</td>
                <td>{toUpperCase(pair.model_number)}</td>
                <td>{pair.Quantity}</td>
                <td>{pair.warranty_type}</td>
                <td><strong>{formatDate(pair.warranty_expiry_date)}</strong></td>
                <td>{pair.warranty_price}</td>
                <td>{pair.product_price}</td>
                <td>{pair.store_number}</td>
                <td>
                  {/* <Button onClick={() => handlePairButtonClick(pair)}>Pair</Button> */}
                  <Button onClick={() => handlePairButtonClick(pair, true)}>Pair</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <h2 className="text-center mt-4" style={{ color: "#343a40" }}>Advance Search Filter </h2>
      <div className="row mb-3">
        <div className="col-md-12">
          <div className="border p-3 d-flex justify-content-center" style={{ background: "#f8f9fa" }}>
            <form onSubmit={handleSubmit} className="w-100">
              <div className="row">
                <div className="col-md" style={{marginTop:'5px'}}>
                  <label htmlFor="startDate"><b>Start Date:</b></label>
                  <DatePicker
                    id="startDate"
                    selected={formData.startDate}
                    onChange={(date) => handleDateChange(date, "startDate")}
                    className="form-control"
                  />
                </div>
                <div className="col-md mt-4" >
                  <label style={{marginTop:'17px'}} htmlFor="endDate"><b>End Date:</b></label>
                  <DatePicker
                    id="endDate"
                    selected={formData.endDate}
                    onChange={(date) => handleDateChange(date, "endDate")}
                    className="form-control"
                  />
                </div>
                <div className="col-md">
                  <label htmlFor="customerName"><b>Customer Name:</b></label>
                  <input
                    type="text"
                    id="customerName"
                    name="customerName"
                    value={formData.customerName}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md">
                  <label htmlFor="trans"><b>Transaction Number:</b></label>
                  <input
                    type="text"
                    id="trans"
                    name="trans"
                    value={formData.trans}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md">
                  <label htmlFor="location"><b>Location:</b></label>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md align-self-end d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>


      {isSearching && (
        <div className="text-center my-4">
          <div className="spinner-border text-primary" role="status" style={{ marginTop: "50px" }}>
            <span className="visually-hidden">Searching...</span>
          </div>
          <p className="mt-2"></p>
        </div>
      )}


      <div className="row">
        <div className="col-md-12">
          <div className="mt-4">
            <h4 className="text-center" style={{ color: "#343a40" }}>Search Results</h4>
            <table className="table" style={{ background: "#f9f9f9" }}>
              <thead style={{ backgroundColor: "#343a40", color: "white" }}>
                <tr>
                  <th>Warranty Sell Date</th>
                  <th>Product Sell Date</th>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Transaction Number</th>
                  <th>Brand</th>
                  <th>Model Number</th>
                  <th>QTY</th>
                  <th>Warranty Type</th>
                  <th>Warranty Expiration</th>
                  <th>Warranty Price</th>
                  <th>Product Price</th>
                  <th>Store Location</th>
                  <th>Pair</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((result, index) => (
                  <tr key={index}>
                    <td>{result.date_sale}</td>
                    <td>{result.s_date_sold}</td>
                    <td>{result.name}</td>
                    <td>{result.address}</td>
                    <td>{result.email}</td>
                    <td>{result.phone_number}</td>
                    <td>{result.transaction_number}</td>
                    <td>{toUpperCase(result.brand)}</td>
                    <td>{toUpperCase(result.model_number)}</td>
                    <td>{result.Quantity}</td>
                    <td>{result.warranty_type}</td>
                    <td>{result.warranty_expiry_date}</td>
                    <td>{result.warranty_price}</td>
                    <td>{result.product_price}</td>
                    <td>{result.loc}</td>
                    <td>
                      {/* <Button onClick={() => handlePairButtonClick(result)}>Pair</Button> */}
                      <Button onClick={() => handlePairButtonClick(result, false)}>Pair</Button>
                    </td>
                  </tr>
                ))}
                {searchResults.length === 0 && (
                  <tr>
                    <td colSpan="8" className="text-center" style={{ fontWeight: "bold" }}>
                      {searchMessage}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PairButton;
