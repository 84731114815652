// import React, { useState } from "react";
// import axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";

// const AdminLogin = ({ onAdminLogin }) => {
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState("");


//   const handleLogin = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/login/`, {
//         username,
//         password,
//       });
//       if (response.data.success) {
//         console.log("User logged in successfully");
//         const { username } = response.data;
//         onAdminLogin(username);
//         setError(""); // Clear the error message
//       } else {
//         setError("Invalid username or password");
//       }
//     } catch (error) {
//       console.error("Login failed:", error.message);
//       setError("If you forget your login creds please contact Mr.Sana");
//     }
//   };


  

//   return (
//     <div className="container-fluid h-100">
//       <div className="row h-100 justify-content-center align-items-center">
//         <div className="col-md-4">
//           <div className="card shadow-lg p-4">
//             <h2 className="text-center mb-4">
//               <FontAwesomeIcon icon={faUser} className="mr-2" /> Admin Login
//             </h2>
//             <form onSubmit={handleLogin}>
//               <div className="form-group mb-3">
//                 <div className="input-group">
//                   <div className="input-group-prepend">
//                     <span className="input-group-text">
//                       <FontAwesomeIcon icon={faUser} />
//                     </span>
//                   </div>
//                   <input
//                     type="text"
//                     className="form-control"
//                     placeholder="Username"
//                     value={username}
//                     onChange={(e) => setUsername(e.target.value)}
//                     required
//                   />
//                 </div>
//               </div>
//               <div className="form-group mb-3">
//                 <div className="input-group">
//                   <div className="input-group-prepend">
//                     <span className="input-group-text">
//                       <FontAwesomeIcon icon={faLock} />
//                     </span>
//                   </div>
//                   <input
//                     type="password"
//                     className="form-control"
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                   />
//                 </div>
//               </div>
//               <button type="submit" className="btn btn-primary btn-block">
//                 Login
//               </button>
//               {error && (
//                 <p className="text-danger mt-3 text-center">{error}</p>
//               )}
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdminLogin;

import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";

const AdminLogin = ({ onAdminLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/login/`, {
        username,
        password,
      });
      if (response.data.success) {
        console.log("User logged in successfully");
        const { username, role } = response.data;
        onAdminLogin(username, role); // Pass role to the parent component
        // console.log(`Logged in user role: ${role}`); // Log the user role to the console
        setError(""); // Clear the error message
      } else {
        setError("Invalid username or password");
      }
    } catch (error) {
      console.error("Login failed:", error.message);
      setError("Please Enter Correct Credentials");
    }
  };
  

  return (
    <div className="container-fluid h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-md-4">
          <div className="card shadow-lg p-4">
            <h2 className="text-center mb-4">
              <FontAwesomeIcon icon={faUser} className="mr-2" /> Admin Login
            </h2>
            <form onSubmit={handleLogin}>
              <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block">
                Login
              </button>
              {error && (
                <p className="text-danger mt-3 text-center">{error}</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
