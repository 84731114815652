import React, { useState, useCallback, useEffect } from "react"; //useRef we have remove the href because of the non editbale fields are removed 
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import styled from 'styled-components';
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  margin-top: 1rem;
`;

const Table = styled.table`
  font-family: Arial, sans-serif;
  font-size: 14px;
  width: 100%;
`;

const TableHeader = styled.thead`
  background-color: #343a40;
  color: white;
`;

const TableCell = styled.td`
  vertical-align: middle;
`;

const Button = styled.button`
  font-size: 12px;
`;

const Header = styled.h2`
  text-align: center;
  margin-bottom: 16px;
`;

const NoClaims = styled.strong`
  font-size: 24px;
`;

const Footer = styled.footer`
  background-color: #343a40;
  color: white;
  padding: 1rem 2rem;
`;


const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PageInfo = styled.div`
  font-size: 0.9rem;
`;

const PageControls = styled.div`
  display: flex;
  align-items: center;
`;



const PageButton = styled.button`
  margin: 0 5px;
  padding: 0.5rem 1rem;
  border: none;
  background-color: ${props => props.active ? '#007bff' : 'transparent'};
  color: ${props => props.active ? 'white' : '#007bff'};
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${props => props.active ? '#0056b3' : 'rgba(0, 123, 255, 0.1)'};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;


const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
};

const CustomerClaimsList = ({ claims, username, userRole }) => {
  const [localClaims, setLocalClaims] = useState([]);
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const [pendingChanges, setPendingChanges] = useState({});
  const [temporaryChanges, setTemporaryChanges] = useState({});
  const [statusChanges, setStatusChanges] = useState({});

   // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const claimsPerPage = 100;

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/customer_claims/`);
      const formattedClaims = response.data.map((claim) => ({
        ...claim,
        created_date_only: formatDate(claim.created_date_only),
        full_name: formatFullName(claim.full_name),
      }));
      // console.log('Fetched claims:', formattedClaims); // Debugging line
      setLocalClaims(formattedClaims);
    } catch (error) {
      console.error("Error fetching claims:", error.message);
    }
  }, []);

  useEffect(() => {
    if (claims.length === 0) {
      fetchData();
    } else {
      setLocalClaims(Array.isArray(claims) ? claims : []);
    }
  }, [claims, fetchData]);



  // Calculate paginated claims
  const indexOfLastClaim = currentPage * claimsPerPage;
  const indexOfFirstClaim = indexOfLastClaim - claimsPerPage;
  const currentClaims = localClaims.slice(indexOfFirstClaim, indexOfLastClaim);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);





  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const formatFullName = (fullName) => {
    const [firstName, lastName] = fullName.split(" ");
    return `${lastName} ${firstName}`;
  };

  const handleAction = async (action, claim) => {
    switch (action) {
      case 'submit':
        if (pendingChanges[claim.id]) {
          const {
            internal_status,
            employee_description,
            notes_for_customer,
            money_spent
          } = pendingChanges[claim.id];

          const previousStatus = claim.internal_status;
          let reasonForStatusChange = '';

          if (statusChanges[claim.id]) {
            reasonForStatusChange = window.prompt(
              `Changing the status of ${claim.full_name} from ${previousStatus} to ${internal_status}\nPlease provide the reason for the status change:`,
              ''
            );

            if (reasonForStatusChange === null || reasonForStatusChange.trim() === '') {
              alert('Please provide a valid reason for the status change.');
              setPendingChanges((prevChanges) => ({
                ...prevChanges,
                [claim.id]: { ...prevChanges[claim.id], internal_status: claim.internal_status },
              }));
              setTemporaryChanges((prevChanges) => ({
                ...prevChanges,
                [claim.id]: { ...prevChanges[claim.id], internal_status: claim.internal_status },
              }));
              return;
            }
          }

          const currentDate = new Date().toISOString();
          const updateData = {};

          // Add only changed fields to the updateData
          if (internal_status !== undefined) {
            updateData.internal_status = internal_status;
            updateData.reason_for_status_change = reasonForStatusChange;
          }

          if (employee_description !== undefined) {
            updateData.employee_description = `${employee_description} (${username})`;
          }

          if (notes_for_customer !== undefined) {
            updateData.notes_for_customer = `${notes_for_customer} (${username})`;
          }

          // Ensure money_spent is set to 0 if no value is provided
          if (money_spent !== undefined && money_spent !== '') {
            updateData.money_spent = parseFloat(money_spent).toFixed(2);
          } else {
            updateData.money_spent = 0;
          }

          updateData.update_date = currentDate;

          console.log('Update data:', updateData);  // Check the output in the console

          const claimIndex = localClaims.findIndex((c) => c.id === claim.id);
          const updatedClaims = [...localClaims];
          updatedClaims[claimIndex] = { ...updatedClaims[claimIndex], ...updateData };

          setLocalClaims(updatedClaims);
          await saveClaimUpdates(claim.id, updateData);

          setPendingChanges((prevChanges) => ({ ...prevChanges, [claim.id]: undefined }));
          setTemporaryChanges((prevChanges) => ({ ...prevChanges, [claim.id]: undefined }));
          setStatusChanges((prevChanges) => ({ ...prevChanges, [claim.id]: undefined }));
        }
        break;
      case "submitWithEmail":
        const notes_for_customer = pendingChanges[claim.id]?.notes_for_customer || claim.notes_for_customer;
        const internal_status = pendingChanges[claim.id]?.internal_status || claim.internal_status;

        if (!notes_for_customer || !internal_status || notes_for_customer === claim.notes_for_customer || internal_status === claim.internal_status) {
          alert("Please change both notes_for_customer and internal_status to submit with email.");
          return;
        }

        const reason_for_status_change = window.prompt(
          `Changing the status of ${claim.full_name} from ${claim.internal_status} to ${internal_status}\nPlease provide the reason for the status change:`,
          ''
        );

        if (reason_for_status_change !== null && reason_for_status_change.trim() !== '') {
          await handleSubmitWithEmail(claim.id, `${notes_for_customer} (${username})`, internal_status, reason_for_status_change);
        } else {
          alert("Please provide a valid reason for the status change.");
        }
        break;
      case "schedule":
        const claimWithParsedAddress = {
          ...claim,
          address1: claim.address,
          city: claim.city || '',
          state: claim.state || '',
          zip: claim.zipcode || claim.zip || ''  // Use zipcode if available, otherwise use zip
        };

        history.push(`/dispatch_track_schedule/${claim.id}`, { claim: claimWithParsedAddress });
        break;
      case "pair":
        history.push(`/PairButton/${claim.id}`, { claim });
        break;
      default:
        break;
      case "details":
        window.open(`/customer_details/${claim.id}`, "_blank");
        break;
    }
    toggleDropdown();
  };

  const handleSubmitWithEmail = async (claimId, notesForCustomer, internalStatus, reasonForStatusChange) => {
    try {
      // Update the customer claim with email
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/update_customer_claim_with_email/${claimId}/`, {
        notes_for_customer: notesForCustomer,
        internal_status: internalStatus,
        reason_for_status_change: reasonForStatusChange
      });

      // Create a message shortcut
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create_message_shortcut/`, {
        shortcut: claimId.toString(),  // Convert claim.id to string if necessary
        message: notesForCustomer
      });

      console.log('Email and update response:');
      fetchData();
    } catch (error) {
      console.error("Error updating claim with email or saving message shortcut:", error.message);
    }
  };





  const saveClaimUpdates = async (claimId, updates) => {
    if (!('money_spent' in updates) || updates.money_spent === '') {
      updates.money_spent = 0;
    }
    try {
      const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/customer_claims/${claimId}/`, updates);
      console.log('Update response:', response.data);
    } catch (error) {
      console.error("Error updating claim:", error.message);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const replaceNoneWithZero = (value) => {
    return value !== 'None' ? value : 0;
  };
  const toUpperCase = (text) => {
    return text ? text.toUpperCase() : '';
  };

  return (
    <Container>
      <Header>Customer Claims</Header>
      <div className="table-responsive">
        <Table className="table table-striped table-hover">
          <TableHeader>
            <tr>
              <th>Request Date</th>
              <th>Name</th>
              <th>Serial Number</th>
              <th>Customer Description</th>
              <th>Product Model Number</th>
              <th>Product Price</th>
              <th>Warranty Model Number</th>
              <th>Warranty Price</th>
              <th>Product Purchase Date</th>
              <th>Brand Name</th>

              <th>Service claim</th>
              {/* <th>Employee Notes</th> */}
              {/* <th>Notes for Customer</th> */}
              {/* <th>Money Spent</th> */}
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </TableHeader>
          <tbody>
            {currentClaims.map((claim, index) => (
              <tr key={index} className="align-middle" style={{
                backgroundColor: claim.id.startsWith('RC') || claim.id.length === 14 ? 'pink' : 'inherit'
              }}
              >
                <TableCell className="text-center">
                  <div>{formatDate(claim.created_date_only)}</div>
                  <Button
                    className="btn btn-primary btn-sm mt-2"
                    onClick={() => window.open(`/customer_details/${claim.id}`, "_blank")}
                  >
                    Details
                  </Button>
                </TableCell>
                <TableCell>{claim.full_name}</TableCell>
                <TableCell>{toUpperCase(claim.serial_number)}</TableCell>
                <TableCell>
                  <ShortenedDescription description={claim.customer_description} />
                </TableCell>
                <TableCell>{toUpperCase(claim.model_number)}</TableCell>
                <TableCell>{claim.price_product ? `$${claim.price_product}` : ''}</TableCell>
                <TableCell>{claim.warranty_id}</TableCell>
                <TableCell>{claim.price_warranty ? `$${claim.price_warranty}` : ''}</TableCell>
                <TableCell>{claim.purchase_date}</TableCell>
                <TableCell>{toUpperCase(claim.brand)}</TableCell>
                <TableCell>
                  {claim.id.startsWith('RC') || claim.id.length === 14 ? 'Yes' : 'No'}
                </TableCell>                {/* <TableCell>
                  <EditableCell
                    contentEditable={true}
                    onBlur={(e) => handleInputChange(claim.id, "employee_description", e.target.textContent)}
                    className="form-control"
                    style={{ minHeight: '20px' }}
                  >
                    {temporaryChanges[claim.id]?.employee_description || ''}
                  </EditableCell>
                </TableCell>
                <TableCell>
                  <EditableCell
                    contentEditable={true}
                    onBlur={(e) => handleInputChange(claim.id, "notes_for_customer", e.target.textContent)}
                    className="form-control"
                    style={{ minHeight: '20px' }}
                  >
                    {temporaryChanges[claim.id]?.notes_for_customer || ''}
                  </EditableCell>
                </TableCell>
                <TableCell>
                  <EditableCell
                    contentEditable={true}
                    onBlur={(e) => handleInputChange(claim.id, "money_spent", e.target.textContent)}
                    className="form-control"
                  >
                    {temporaryChanges[claim.id]?.money_spent !== undefined ? temporaryChanges[claim.id].money_spent : ''}
                  </EditableCell>
                </TableCell> */}








                {/* changed status from the customer claim list  */}




                {/* <TableCell>
                  <select
                    value={temporaryChanges[claim.id]?.internal_status || claim.internal_status}
                    onChange={(e) => handleInputChange(claim.id, "internal_status", e.target.value)}
                    className="form-control"
                    style={{ fontSize: '14px' }}
                  >
                    {(userRole === 'employee' ? employeeStatusOptions : allStatusOptions).map((status) => (
                      <option key={status} value={status}>{status}</option>
                    ))}
                  </select>
                </TableCell> */}
                <TableCell>
                  <div
                    className="form-control"
                    style={{
                      fontSize: '14px',
                      backgroundColor: '#f8f9fa',
                      border: '1px solid #ced4da',
                      padding: '0.375rem 0.75rem',
                      borderRadius: '0.25rem',
                      color: '#495057',
                      maxWidth: '100%', // Ensure the div does not exceed the cell width
                      whiteSpace: 'nowrap', // Prevent text from wrapping
                      overflow: 'hidden', // Hide overflow text
                      textOverflow: 'ellipsis' // Show ellipsis for overflow text
                    }}
                  >
                    {temporaryChanges[claim.id]?.internal_status || claim.internal_status}
                  </div>
                </TableCell>





                {/* <TableCell>
                  <Dropdown isOpen={selectedClaimId === claim.id} toggle={() => setSelectedClaimId(selectedClaimId === claim.id ? null : claim.id)}>
                    <DropdownToggle caret className="btn btn-primary btn-sm" style={{ fontSize: '12px' }}>
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem style={{ color: 'white', backgroundColor: 'blue', padding: '5px', borderRadius: '5px' }} onClick={() => handleAction("submit", claim)}>Submit</DropdownItem>
                      <DropdownItem style={{ color: 'white', backgroundColor: '#555555', padding: '5px', borderRadius: '5px' }} onClick={() => handleAction("schedule", claim)}>Schedule</DropdownItem>
                      <DropdownItem style={{ color: 'white', backgroundColor: '#008CBA', padding: '5px', borderRadius: '5px' }} onClick={() => handleAction("pair", claim)}>Pair</DropdownItem>
                      <DropdownItem style={{ color: 'white', backgroundColor: 'green', padding: '5px', borderRadius: '5px' }} onClick={() => handleAction("submitWithEmail", claim)}>Submit with Email</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </TableCell> */}

                <TableCell>
                  <Dropdown isOpen={selectedClaimId === claim.id} toggle={() => setSelectedClaimId(selectedClaimId === claim.id ? null : claim.id)}>
                    <DropdownToggle caret className="btn btn-primary btn-sm" style={{ fontSize: '12px' }}>
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* <DropdownItem style={{ color: 'white', backgroundColor: 'blue', padding: '5px', borderRadius: '5px' }} onClick={() => handleAction("submit", claim)}>Submit</DropdownItem> */}
                      {/* <DropdownItem style={{ color: 'white', backgroundColor: '#555555', padding: '5px', borderRadius: '5px' }} onClick={() => handleAction("schedule", claim)}>Schedule</DropdownItem> */}
                      <DropdownItem
                        style={{ color: 'white', backgroundColor: '#007bff', padding: '5px', borderRadius: '5px' }}
                        onClick={() => handleAction("details", claim)}
                      >
                        Details
                      </DropdownItem>
                      {!(claim.warranty_id || claim.price_product) && (
                        <DropdownItem
                          style={{ color: 'white', backgroundColor: '#008CBA', padding: '5px', borderRadius: '5px' }}
                          onClick={() => handleAction("pair", claim)}
                        >
                          Pair
                        </DropdownItem>
                      )}
                      {/* <DropdownItem style={{ color: 'white', backgroundColor: 'green', padding: '5px', borderRadius: '5px' }} onClick={() => handleAction("submitWithEmail", claim)}>Submit with Email</DropdownItem> */}
                    </DropdownMenu>
                  </Dropdown>
                </TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>


	      <Footer>
      <PaginationContainer>
        <PageInfo>
          Showing {indexOfFirstClaim + 1} to {Math.min(indexOfLastClaim, localClaims.length)} of {localClaims.length} entries
        </PageInfo>
        <PageControls>
          <PageButton onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </PageButton>
          {Array.from({ length: Math.ceil(localClaims.length / claimsPerPage) }, (_, index) => (
            <PageButton
              key={index + 1}
              active={currentPage === index + 1}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </PageButton>
          ))}
          <PageButton onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(localClaims.length / claimsPerPage)}>
            <FontAwesomeIcon icon={faChevronRight} />
          </PageButton>
        </PageControls>
      </PaginationContainer>
    </Footer>



      {localClaims.length === 0 && (
        <div className="row mt-5">
          <div className="col text-center">
            <NoClaims>No claims found</NoClaims>
          </div>
        </div>
      )}
    </Container>
  );
};

const ShortenedDescription = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const shortenedDescription = () => {
    return description.length > 50 ? `${description.substring(0, 50)}...` : description;
  };

  return (
    <>
      <div>
        {isExpanded ? (
          <>
            {description}
            <Button className="btn btn-link btn-sm ml-2" onClick={handleToggle} style={{ fontSize: '12px' }}>
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </>
        ) : (
          <>
            {shortenedDescription()}
            {description.length > 50 && (
              <Button className="btn btn-link btn-sm ml-2" onClick={handleToggle} style={{ fontSize: '12px' }}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CustomerClaimsList;
