import React, { createContext, useState, useEffect } from 'react';

const ExpirationDateContext = createContext();

export const ExpirationDateProvider = ({ children }) => {
  const [expirationDates, setExpirationDates] = useState(() => {
    const savedDates = localStorage.getItem('expirationDates');
    return savedDates ? JSON.parse(savedDates) : {};
  });

  const updateExpirationDate = (claimId, expirationDate) => {
    setExpirationDates((prev) => {
      const updatedDates = { ...prev, [claimId]: expirationDate };
      localStorage.setItem('expirationDates', JSON.stringify(updatedDates));
      return updatedDates;
    });
  };

  useEffect(() => {
    const savedDates = localStorage.getItem('expirationDates');
    if (savedDates) {
      setExpirationDates(JSON.parse(savedDates));
    }
  }, []);

  return (
    <ExpirationDateContext.Provider value={{ expirationDates, updateExpirationDate }}>
      {children}
    </ExpirationDateContext.Provider>
  );
};

export default ExpirationDateContext;
