import React, { useEffect, useState } from "react";
import { Table, Card, Row, Col, Form, Button } from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";
import { useParams } from "react-router-dom"; // Import useParams

// Styled Table with increased width
const StyledTable = styled(Table)`
  margin-bottom: 30px;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
`;

const CardContainer = styled(Card)`
  border-radius: 15px;
  margin-top: 10px;
  width: 100%;
`;

const CardBodyContainer = styled(Card.Body)`
  background: #f8f9fa;
  border-radius: 15px;
  padding: 20px;
`;

const TableRow = styled.tr`
  font-size: 14px;
`;

const TableHeader = styled.th`
  background-color: yellow;
  color: black;
  font-size: 14px;
  text-align: center;
`;

const TableCell = styled.td`
  text-align: center;
  font-size: 14px;
`;

const EditableCell = styled(Form.Control)`
  width: 100%;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
`;

const AddButton = styled(Button)`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  margin-top: 10px;
`;

const CustomerDetails2 = () => {
  const { claimId } = useParams(); // Extract claimId from the URL
  const [partsOrderData, setPartsOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newRow, setNewRow] = useState({
    supplier_name: "",
    part_number: "",
    price: "",
    order_date: "",
  });

  // Fetch Parts Order Data
  useEffect(() => {
    const fetchPartsOrderData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/ordered_parts/?claim_id=${claimId}`
        );
        setPartsOrderData(response.data);
      } catch (error) {
        console.error("Error fetching parts order data:", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPartsOrderData();
  }, [claimId]);

  // Format date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Date(
      date.getTime() + date.getTimezoneOffset() * 60000
    ).toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  };

  // Handle input changes for the new row
  const handleNewRowChange = (field, value) => {
    setNewRow((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Add a new ordered part
  const handleAddNewRow = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/ordered_parts/`,
        {
          ...newRow,
          claim_id: claimId,
          amount: newRow.price,
        }
      );

      // Add the new row to the table and clear the input fields
      setPartsOrderData((prevData) => [...prevData, response.data]);
      setNewRow({
        supplier_name: "",
        part_number: "",
        price: "",
        order_date: "",
      });
      alert("New ordered part added successfully.");
    } catch (error) {
      console.error("Error adding new row:", error.message);
      alert("Failed to add the new ordered part. Please try again.");
    }
  };

  return (
    <Row>
      <Col>
        <CardContainer className="shadow-lg border-0">
          <CardBodyContainer>
            <h4 className="mb-4">
              <b>
                Ordered Parts
                <span style={{ color: "red" }}></span>
              </b>
            </h4>
            <StyledTable
              striped
              bordered
              hover
              responsive
              className="shadow-lg"
            >
              <thead>
                <TableRow>
                  <TableHeader>Supplier</TableHeader>
                  <TableHeader>Part Number</TableHeader>
                  <TableHeader>Price</TableHeader>
                  <TableHeader>Order Date</TableHeader>
                  <TableHeader>Actions</TableHeader>
                </TableRow>
              </thead>
              <tbody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan="8">Loading...</TableCell>
                  </TableRow>
                ) : partsOrderData.length > 0 ? (
                  partsOrderData.map((order, index) => (
                    <TableRow key={index}>
                      <TableCell>{order.supplier_name}</TableCell>
                      <TableCell>{order.part_number}</TableCell>
                      <TableCell>
                        ${order.amount ? parseFloat(order.amount).toFixed(2) : "0.00"}
                      </TableCell>
                      <TableCell>{formatDate(order.order_date)}</TableCell>
                      <TableCell>Read-only</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="8">
                      No parts order data available.
                    </TableCell>
                  </TableRow>
                )}

                {/* New Row for Adding Data */}
                <TableRow>
                  <TableCell>
                    <EditableCell
                      type="text"
                      placeholder="Enter Supplier Name"
                      value={newRow.supplier_name}
                      onChange={(e) =>
                        handleNewRowChange("supplier_name", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <EditableCell
                      type="text"
                      placeholder="Enter Part Number"
                      value={newRow.part_number}
                      onChange={(e) =>
                        handleNewRowChange("part_number", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <EditableCell
                      type="number"
                      placeholder="Enter Price"
                      value={newRow.price}
                      onChange={(e) =>
                        handleNewRowChange("price", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <EditableCell
                      type="date"
                      value={newRow.order_date}
                      onChange={(e) =>
                        handleNewRowChange("order_date", e.target.value)
                      }
                      max="9999-12-31" // Ensures the year stays within 4 digits
                    />
                  </TableCell>
                  <TableCell>
                    <AddButton variant="primary" onClick={handleAddNewRow}>
                      Add Ordered Part
                    </AddButton>
                  </TableCell>
                </TableRow>
              </tbody>
            </StyledTable>
          </CardBodyContainer>
        </CardContainer>
      </Col>
    </Row>
  );
};

export default CustomerDetails2;
