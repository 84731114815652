import React, { useState, useEffect } from "react";
import axios from "axios";
import CustomerClaimsList from "./CustomerClaimsList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FaSearch, FaCalendarAlt, FaUser, FaEnvelope, FaPhone, FaHashtag, FaCity, FaMapMarkerAlt, FaList } from "react-icons/fa";
import { Container, Row, Col, Form, Button, Card, Spinner, InputGroup, FormControl, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const CustomerRequests = ({ username, userRole }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [formData, setFormData] = useState({
    claimId: "",
    startDate: null,
    endDate: null,
    name: "",
    email: "",
    phoneNumber: "",
    transactionNumber: "",
    modelNumber: "",
    city: "",
    state: "",
    status: "",

  });
  const [isOpenStatusDropdown, setIsOpenStatusDropdown] = useState(false);

  useEffect(() => {
    // Fetch default pending claims on initial load
    fetchClaims({ status: "Pending" });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date, field) => {
    setFormData({ ...formData, [field]: date });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isFormEmpty = Object.values(formData).every((val) => val === "" || val === null);

    if (isFormEmpty) {
      setNoResults(false);
      setSearchResults([]); // Clear previous search results
      fetchClaims({ status: "Pending" }); // Fetch default pending claims
    } else {
      fetchClaims(formData);
    }
  };

  const fetchClaims = async (queryParams) => {
    setIsLoading(true);

    try {
      const isSearchEmpty = Object.values(queryParams).every((val) => val === "" || val === null);

      let response;
      if (isSearchEmpty) {
        response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/customer_claims/`);
      } else {
        const formattedData = {
          ...queryParams,
          startDate: queryParams.startDate ? queryParams.startDate.toISOString().split("T")[0] : "",
          endDate: queryParams.endDate ? queryParams.endDate.toISOString().split("T")[0] : "",
        };
        response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/search_claim/`, { params: formattedData });
      }

      // console.log("API Response:", response.data);

      const data = response.data.results || [];
      setSearchResults(data);
      setNoResults(data.length === 0);
    } catch (error) {
      console.error("Error searching claims:", error);
      setNoResults(true);
    } finally {
      setIsLoading(false);
    }
  };

  const statusOptions = [
    "All Claims (Except Archive)",
    "Pending",
    "In Progress",
    "Declined",
    "Pending Manager Approval",
    "Repair in Progress",
    "Repaired",
    "Replaced/Credit Issued",
    "Archive",
    "Service Claims",
    "Closed"
  ];

  const handleStatusSelect = (status) => {
    setFormData({ ...formData, status });
    setIsOpenStatusDropdown(false);
  };

  return (
    <Container fluid className="p-4" style={{ background: "linear-gradient(135deg, #f5f7fa, #c3cfe2)", minHeight: "100vh" }}>
      <Card className="shadow-lg border-0" style={{ borderRadius: "15px" }}>
        <Card.Body style={{ background: "#f8f9fa", borderRadius: "15px" }}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={3} className="mb-3">
                <Form.Group>
                  <Form.Label><b>Claim ID:</b></Form.Label>
                  <InputGroup>
                    <InputGroup.Text><FaHashtag /></InputGroup.Text>
                    <FormControl
                      type="text"
                      name="claimId"
                      value={formData.claimId}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group>
                  <Form.Label><b>Submission Range Start:</b></Form.Label>
                  <InputGroup>
                    <InputGroup.Text><FaCalendarAlt /></InputGroup.Text>
                    <DatePicker
                      selected={formData.startDate}
                      onChange={(date) => handleDateChange(date, "startDate")}
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group>
                  <Form.Label><b>Submission Range End:</b></Form.Label>
                  <InputGroup>
                    <InputGroup.Text><FaCalendarAlt /></InputGroup.Text>
                    <DatePicker
                      selected={formData.endDate}
                      onChange={(date) => handleDateChange(date, "endDate")}
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group>
                  <Form.Label><b>Name:</b></Form.Label>
                  <InputGroup>
                    <InputGroup.Text><FaUser /></InputGroup.Text>
                    <FormControl
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group>
                  <Form.Label><b>Email:</b></Form.Label>
                  <InputGroup>
                    <InputGroup.Text><FaEnvelope /></InputGroup.Text>
                    <FormControl
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group>
                  <Form.Label><b>Phone Number:</b></Form.Label>
                  <InputGroup>
                    <InputGroup.Text><FaPhone /></InputGroup.Text>
                    <FormControl
                      type="tel"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group>
                  <Form.Label><b>Transaction Number:</b></Form.Label>
                  <InputGroup>
                    <InputGroup.Text><FaHashtag /></InputGroup.Text>
                    <FormControl
                      type="text"
                      name="transactionNumber"
                      value={formData.transactionNumber}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group>
                  <Form.Label><b>Model Number:</b></Form.Label>
                  <InputGroup>
                    <InputGroup.Text><FaList /></InputGroup.Text>
                    <FormControl
                      type="text"
                      name="modelNumber"
                      value={formData.modelNumber}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group>
                  <Form.Label><b>City:</b></Form.Label>
                  <InputGroup>
                    <InputGroup.Text><FaCity /></InputGroup.Text>
                    <FormControl
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group>
                  <Form.Label><b>State:</b></Form.Label>
                  <InputGroup>
                    <InputGroup.Text><FaMapMarkerAlt /></InputGroup.Text>
                    <FormControl
                      type="text"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3 mt-4">
                <Form.Group>
                  <Form.Label><b>Status:</b></Form.Label>
                  <ButtonDropdown
                    isOpen={isOpenStatusDropdown}
                    toggle={() => setIsOpenStatusDropdown(!isOpenStatusDropdown)}
                  >
                    <DropdownToggle caret color="primary" style={{ width: "100%", textAlign: "left" }}>
                      {formData.status || "Select Status"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {statusOptions.map((status) => (
                        <DropdownItem key={status} onClick={() => handleStatusSelect(status)}>
                          {status}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3 d-flex align-items-end justify-content-end">
                <Button type="submit" variant="primary" className="w-100">
                  {isLoading ? <Spinner animation="border" size="sm" /> : <><FaSearch /> Search</>}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <div id="searchResults" className="mt-4">
        {isLoading ? (
          <Alert variant="info">
            Loading...
          </Alert>
        ) : noResults ? (
          <Alert variant="info">
            No customer exists. Please search again.
          </Alert>
        ) : (
          <CustomerClaimsList claims={searchResults} username={username} userRole={userRole} />
        )}
      </div>
    </Container>
  );
};

export default CustomerRequests;
