import React, { useState, useCallback, useEffect, useContext } from 'react';
import axios from 'axios';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Table, Card, Alert, Modal,Spinner } from "react-bootstrap";
import styled from 'styled-components';
import ExpirationDateContext from '../component/ExpirationDateContext';
import "bootstrap/dist/css/bootstrap.min.css";
import { FaEdit, FaSave, FaCopy } from "react-icons/fa";

import CustomerDetails2 from "./Customer_details_2";
import CustomerDetails3 from "./Customer_detials_3";

const EditableCell = styled.div`
  font-size: 14px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:focus {
    outline: none;
  }
  overflow-wrap: break-word;
  min-height: 80px;
  padding: 8px;
`;

const TableCell = styled.td`
  vertical-align: middle;
`;

const StyledTable = styled(Table)`
  margin-bottom: 30px;
`;


const calculateExpirationDate = (purchaseDate, warrantyModel) => {
  // Warranty periods as months
  const warrantyPeriods = {
    12: 12,   // 12 months
    24: 24,   // 24 months
    36: 36,   // 36 months
    48: 48,   // 48 months
    60: 60    // 60 months
  };

  if (warrantyModel && warrantyModel === 'MFR Warranty') {
    return addMonthsToDate(purchaseDate, 12);
  }

  // Extract the first two digits after the alphabetic prefix
  const modelMatch = warrantyModel.match(/[A-Z]+(\d{2})/);
  if (!modelMatch) return null; // Return null if model number format is incorrect
  
  const modelNumber = modelMatch[1]; // Extract the two digits
  const durationMonths = warrantyPeriods[modelNumber] || 0; // Get the corresponding warranty period
  
  if (!purchaseDate || durationMonths === 0) return null;

  // Add the number of months to the purchase date
  return addMonthsToDate(purchaseDate, durationMonths);
};

// Helper function to add months to a date
const addMonthsToDate = (date, months) => {
  const dateObj = new Date(date);
  dateObj.setMonth(dateObj.getMonth() + months);
  return dateObj.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
};



const CustomerDetails = ({ match, location, username, userRole }) => {
  const { claimId } = match.params;
  const [claim, setClaim] = useState({});
  const [employeeNotes, setEmployeeNotes] = useState('');
  const [notesForCustomer, setNotesForCustomer] = useState('');
  const [moneySpent, setMoneySpent] = useState('');
  const [temporaryStatusChanges, setTemporaryStatusChanges] = useState({});
  const [pendingStatusChanges, setPendingStatusChanges] = useState({});
  const [historicalData, setHistoricalData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [showAllRows, setShowAllRows] = useState(false);
  const [notification, setNotification] = useState(null);
  const [modal, setModal] = useState(false);
  const [copyStatus, setCopyStatus] = useState('');
  const [suggestedPairs, setSuggestedPairs] = useState([]);
  const [claimHistoryData, setClaimHistoryData] = useState([]);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [repairPictures, setRepairPictures] = useState([]);
  const history = useHistory();
  const [messageHistory, setMessageHistory] = useState([]);
  const [pendingChanges, setPendingChanges] = useState({});
  const [isLoadingSuggestedPairs, setIsLoadingSuggestedPairs] = useState(true);
  const isServiceClaim = claimId.startsWith('RC');
  const [expirationDate, setExpirationDate] = useState(null);  // New state for expiration date



  const handleClaimClick = (claimId) => {
    window.open(`/customer_details/${claimId}`, '_blank');
  };


  const allStatusOptions = [
    "Pending",
    "In Progress",
    "Pending Manager Approval",
    "Declined",
    "Repair in Progress",
    "Repaired",
    "Replaced/Credit Issued",
    "Archive",
    "Closed"
  ];

  const employeeStatusOptions = [
    "Pending",
    "In Progress",
    "Pending Manager Approval",
    "Declined",
    "Repair in Progress",
    "Repaired",
    "Closed"
  ];

  const fetchMessageHistory = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/get_messages_by_claim_id/${claimId}/`);
      setMessageHistory(response.data.messages);
    } catch (error) {
      console.error("Error fetching message history:", error.message);
    }
  }, [claimId]);

  useEffect(() => {
    fetchMessageHistory();
  }, [fetchMessageHistory]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };



  const fetchClaimData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/customer_claims/?claim_id=${claimId}`);
      const updatedClaim = response.data.find(c => c.id === claimId);
      if (updatedClaim) {
        setClaim(updatedClaim);


	// Calculate expiration date based on purchase date and warranty model number
        const calculatedExpirationDate = calculateExpirationDate(updatedClaim.purchase_date, updatedClaim.warranty_id);
        setExpirationDate(calculatedExpirationDate);


        setTemporaryStatusChanges({ [claimId]: updatedClaim.internal_status });
        setPendingChanges({});
      } else {
        console.error("Claim not found in the response");
      }
    } catch (error) {
      console.error("Error fetching claim data:", error.message);
    }
  }, [claimId]);



  const fetchHistoricalData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/historical_customer_requests/${claimId}/`);
      const formattedData = response.data.map(record => ({
        ...record,
        history_date: new Date(record.history_date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
      }));
      setHistoricalData(formattedData);
    } catch (error) {
      console.error("Error fetching historical data:", error.message);
    }
  }, [claimId]);

  const fetchClaimHistory = useCallback(async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/claim_history/`, {
        claim_id: claimId,
      });
      setClaimHistoryData(response.data);
    } catch (error) {
      console.error("Error fetching claim history:", error.message);
    }
  }, [claimId]);

  const fetchRepairPictures = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/repair_pictures/${claimId}/`);
      setRepairPictures(response.data);
    } catch (error) {
      console.error("Error fetching repair pictures:", error.message);
    }
  }, [claimId]);

  useEffect(() => {
    fetchClaimData();
    fetchHistoricalData();
    fetchClaimHistory();
    fetchRepairPictures();
  }, [fetchClaimData, fetchHistoricalData, fetchClaimHistory, fetchRepairPictures]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const handleInputChange = (fieldName, value) => {
    if (fieldName === 'internal_status') {
      setPendingStatusChanges((prevChanges) => ({
        ...prevChanges,
        [claimId]: {
          fieldName,
          value,
          previousStatus: claim.internal_status,
        },
      }));
      setTemporaryStatusChanges((prevChanges) => ({
        ...prevChanges,
        [claimId]: value,
      }));
    } else if (fieldName === 'employeeNotes') {
      setEmployeeNotes(value);
    } else if (fieldName === 'notesForCustomer') {
      setNotesForCustomer(value);
    } else if (fieldName === 'money_spent') {
      setMoneySpent(value);
    }
    else {
      // setClaim({ ...claim, [fieldName]: value });
      setPendingChanges(prevChanges => ({
        ...prevChanges,
        [fieldName]: value
      }));
    }
  };

  const handleFileChange = (e) => {
    setFileToUpload(e.target.files[0]);
  };

  const uploadFile = async () => {
    if (!fileToUpload) {
      setNotification('No file selected.');
      return;
    }

    const formData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('claim_id', claimId);

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload_claim_image/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setFileToUpload(null);
      setNotification('File uploaded successfully.');
      await fetchRepairPictures();
    } catch (error) {
      console.error("Error uploading file:", error.message);
      setNotification('Failed to upload file.');
    }
  };

  const saveClaimUpdates = async () => {
    if (
      notesForCustomer === '' &&
      employeeNotes === '' &&
      (moneySpent === '' || moneySpent === 0)
    ) {
      setNotification('No changes to save.');
      return;
    }

    const updates = {};

    if (notesForCustomer !== '') {
      updates.notes_for_customer = `${notesForCustomer} (${username})`;
    }
    if (employeeNotes !== '') {
      updates.employee_description = `${employeeNotes} (${username})`;
    }
    if (moneySpent !== '') {
      updates.money_spent = moneySpent;
    } else {
      updates.money_spent = 0;
    }

    if (Object.keys(updates).length === 0) {
      setNotification('No changes to save.');
      return;
    }

    try {
      const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/customer_claims/${claimId}/`, updates);
      console.log('Update response:', response.data);
      await fetchClaimData();
      await fetchHistoricalData();
      setNotification('Changes saved successfully.');

      setNotesForCustomer('');
      setEmployeeNotes('');
      setMoneySpent('');
    } catch (error) {
      console.error("Error updating claim:", error.message);
      setNotification('Failed to save changes.');
    }
  };

  const handleCopyClick = (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand('copy');
      setCopyStatus('Copied');
      setTimeout(() => setCopyStatus(''), 2000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
    document.body.removeChild(textarea);
  };

  const formatPhoneNumber = (number) => {
    if (!number || typeof number !== 'string') {
      return '';
    }
    return number.replace(/\D/g, '');
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
	setIsLoadingSuggestedPairs(true);
        const url = claim.id.startsWith('RC') 
        ? `${process.env.REACT_APP_BACKEND_URL}/api/suggested_pairs_repair/` 
        : `${process.env.REACT_APP_BACKEND_URL}/api/suggested_pairs/`;

        // const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/suggested_pairs/`, {
        
          const response = await axios.post(url, {
          full_name: claim.full_name,
          address: `${claim.address}, ${claim.city}, ${claim.state}, ${claim.zipcode}`,
          email: claim.email,
          phone_number: formatPhoneNumber(claim.phone_number),
          transaction_number: claim.transaction_number,
          claimId:claim.id
        });
        setSuggestedPairs(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoadingSuggestedPairs(false); // End loading
      }
    };
    if (claim.full_name) {
      fetchData();
    }
  }, [claim.full_name, claim.address, claim.city, claim.state, claim.zipcode, claim.email, claim.phone_number, claim.transaction_number,claim.id]);






  const { updateExpirationDate } = useContext(ExpirationDateContext);

  const handlePairButtonClick = async (pairData, isFromSuggestedPairs = false) => {
    console.log('Pair Data:', pairData);
    try {
      const formattedPurchaseDate = pairData.s_date_sold ? pairData.s_date_sold.split('T')[0] : '';
      const storeNumber = pairData.store_number || null;

      const pairClaimData = {
        brand: pairData.brand || '',
        transaction_number: pairData.transaction_number || '',
        store: storeNumber || 0,
        purchase_date: formattedPurchaseDate || '',
        model_number: pairData.model_number || '',
        price_product: pairData.product_price || 0.0,
        warranty_price: pairData.warranty_price || 0.0,
        product_price: pairData.product_price || 0.0,
        department_id: pairData.department_id || 0,
        warranty_transaction_number: pairData.warranty_transaction_number || '',
        email: pairData.email || '',
        phone_number: pairData.phone_number || '',
        store_number: storeNumber || 0,
        warranty_expiry_date: pairData.warranty_expiry_date || '',
        full_name: pairData.name || '',
        address: pairData.address || '',
        warranty_type: pairData.warranty_type || '',
      };

      console.log('Pair Claim Data:', pairClaimData);

      const pairClaimResponse = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/pair_claim/${claim.id}/`, pairClaimData);
      console.log('Pairing successful:', pairClaimResponse.data);

      if (isFromSuggestedPairs && isServiceClaim) {
        const createWarrantyProductPairData = {
          trans: pairData.transaction_number || '',
          date_sold: formattedPurchaseDate || '',
          store: storeNumber || 0,
          warranty_id: pairData.warranty_type || '',
          product_retail_price: parseFloat(pairData.product_price) || 0.0,
          brand: pairData.brand || '',
          product_cost: parseFloat(pairData.product_price) || 0.0,
          product_sku: pairData.model_number || '',
          product_qty: pairData.Quantity || 0,
        };

        console.log('Create Warranty Product Pair Data:', createWarrantyProductPairData);

        const createWarrantyProductPairResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create_warranty_product_pair/`, createWarrantyProductPairData);
        console.log('Warranty Product Pair creation successful:', createWarrantyProductPairResponse.data);
      }

      updateExpirationDate(claim.id, pairData.warranty_expiry_date);

      setModal(true);
    } catch (error) {
      console.error('Error pairing:', error);
      if (error.response) {
        console.error('Server response:', error.response.data);
        alert(`Error: ${error.response.data.error || 'Unknown error occurred'}`);
      } else {
        alert('An error occurred while pairing the claim. Please try again.');
      }
    }
  };


  const toggleModal = () => setModal(!modal);

  const saveClaimDetails = async (updates) => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/customer_request/${claimId}/`, updates);
      console.log('Update response:', response.data);
      setClaim(response.data);
      setTemporaryStatusChanges((prevChanges) => ({
        ...prevChanges,
        [claimId]: updates.internal_status,
      }));
    } catch (error) {
      console.error("Error updating claim details:", error.message);
    }
  };

  const handleSubmit = async () => {
    await saveClaimUpdates();
  };

  const handleSubmitStatus = async () => {
    const isReadOnly = claim.internal_status === "Repaired" || claim.internal_status === "Replaced/Credit Issued" || claim.internal_status === "Declined";

    if (isReadOnly) {
      alert("The status is already set to Repaired or Replaced/Credit Issued and cannot be changed.");
      return;
    }

    if (pendingStatusChanges[claimId]) {
      const { fieldName, value, previousStatus } = pendingStatusChanges[claimId];
      const reasonForStatusChange = window.prompt(
        `Changing the status of ${claim.full_name} from ${previousStatus} to ${value}\nPlease provide the reason for the status change:`,
        ''
      );
      if (reasonForStatusChange !== null) {
        const updates = {
          [fieldName]: value,
          reason_for_status_change: reasonForStatusChange,
          update_date: new Date().toISOString(),
        };
        try {
          const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/update_internal_status/${claimId}/`, updates);
          console.log('Update response:', response.data);
          setClaim(response.data);
          setTemporaryStatusChanges((prevChanges) => ({
            ...prevChanges,
            [claimId]: updates.internal_status,
          }));
          setPendingStatusChanges((prevChanges) => ({ ...prevChanges, [claimId]: undefined }));
          window.location.reload();
        } catch (error) {
          console.error("Error updating claim status:", error.message);
        }
      }
    }
  };

  const handleSubmitWithEmail = async () => {
    const notesForCustomerValue = notesForCustomer;
    const internalStatus = temporaryStatusChanges[claim.id] || claim.internal_status;
    const isReadOnly = claim.internal_status === "Repaired" || claim.internal_status === "Replaced/Credit Issued" || claim.internal_status === "Declined";

    if (isReadOnly) {
      alert("The status is already set to Repaired or Replaced/Credit Issued and cannot be changed.");
      return;
    }

    if (!notesForCustomerValue || !internalStatus || notesForCustomerValue === claim.notes_for_customer || internalStatus === claim.internal_status) {
      alert("Please change both notes_for_customer and internal_status to submit with email.");
      return;
    }

    const reasonForStatusChange = window.prompt(
      `Changing the status of ${claim.full_name} from ${claim.internal_status} to ${internalStatus}\nPlease provide the reason for the status change:`,
      ''
    );

    if (reasonForStatusChange !== null && reasonForStatusChange.trim() !== '') {
      try {
        await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/update_customer_claim_with_email/${claim.id}/`, {
          notes_for_customer: `${notesForCustomerValue} (${username})`,
          internal_status: internalStatus,
          reason_for_status_change: reasonForStatusChange,
        });

        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create_message_shortcut/`, {
          shortcut: claim.id.toString(),
          message: `${notesForCustomerValue} (${username})`
        });

        fetchClaimData();
        alert("Status updated, email sent to the customer, and notes saved in message shortcut.");
      } catch (error) {
        console.error("Error updating claim with email or saving message shortcut:", error.message);
      }
    } else {
      alert("Please provide a valid reason for the status change.");
    }
  };

  
  const toggleEditMode = async () => {
    if (editMode) {
      if (Object.keys(pendingChanges).length > 0) {
        try {
          const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/customer_request/${claimId}/`, pendingChanges);
          console.log('Update response:', response.data);
          setClaim(prevClaim => ({ ...prevClaim, ...response.data }));
          setPendingChanges({});
          await fetchClaimData();
          setNotification('Changes saved successfully.');
        } catch (error) {
          console.error("Error updating claim details:", error.message);
          setNotification('Failed to save changes.');
        }
      }
    }
    setEditMode(!editMode);
  };





  const toggleShowAllRows = () => {
    setShowAllRows(!showAllRows);
  };

  const totalMoneySpent = historicalData.reduce((total, record) => {
    const moneySpent = parseFloat(record.money_spent) || 0;
    return total + moneySpent;
  }, 0);

  const handleConfirmPairing = () => {
    setModal(false);
    window.location.reload();
  };

  const shouldHideSuggestedPairs = (claim.warranty_id && claim.price_warranty) || claim.price_product;

  const handleSchedule = () => {
    const claimWithParsedAddress = {
      ...claim,
      address1: claim.address,
      city: claim.city || '',
      state: claim.state || '',
      zip: claim.zipcode || claim.zipcode || ''
    };

    history.push(`/dispatch_track_schedule/${claim.id}`, { claim: claimWithParsedAddress });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000).toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' });
  };

  if (!localStorage.getItem("isLoggedIn")) {
    return <Redirect to="/" />;
  }
  const toUpperCase = (text) => {
    return text ? text.toUpperCase() : '';
  };







 const handleSubmitAll = async () => {
    const updates = {};
    let hasChanges = false;

    // Check if notes for customer or employee notes are provided
    if (notesForCustomer !== '') {
      updates.notes_for_customer = `${notesForCustomer} (${username})`;
      hasChanges = true;
    }
    if (employeeNotes !== '') {
      updates.employee_description = `${employeeNotes} (${username})`;
      hasChanges = true;
    }

    // If no money spent is provided, default to 0
    if (moneySpent !== '') {
      updates.money_spent = moneySpent;
      hasChanges = true;
    } else {
      updates.money_spent = 0;
    }

    // Check if the status has been changed
    if (pendingStatusChanges[claimId]) {
      const { value, previousStatus } = pendingStatusChanges[claimId];
      const reasonForStatusChange = window.prompt(
        `Changing the status of ${claim.full_name} from ${previousStatus} to ${value}\nPlease provide the reason for the status change:`,
        ''
      );

      if (reasonForStatusChange !== null) {
        updates.internal_status = value;
        updates.reason_for_status_change = reasonForStatusChange;
        hasChanges = true;

        // If no notes for customer, employee notes, or money spent is provided, set default employee notes
        if (notesForCustomer === '' && employeeNotes === '' && moneySpent === '') {
          updates.employee_description = `The status is changed by ${username}`;
        }
      }
    }

    // If no changes were made, show a notification
    if (!hasChanges) {
      setNotification('No changes to save.');
      return;
    }

    // Proceed with saving the updates
    try {
      const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/customer_claims/${claimId}/`, updates);
      console.log('Update response:', response.data);
      await fetchClaimData();
      await fetchHistoricalData();
      setNotification('Changes saved successfully.');

      // Reset the form fields
      setNotesForCustomer('');
      setEmployeeNotes('');
      setMoneySpent('');
      setPendingStatusChanges((prevChanges) => ({ ...prevChanges, [claimId]: undefined }));
    } catch (error) {
      console.error("Error updating claim:", error.message);
      setNotification('Failed to save changes.');
    }
  };









 const renderStatusField = () => {
    const normalizedStatus = (status) => {
      const lowerStatus = status ? status.toLowerCase() : '';
      if (['refund', 'credit issued', 'replaced', 'unable to repair', 'replaced/credit issued'].includes(lowerStatus)) {
        return 'Replaced/Credit Issued';
      }
      return status || '';
    };
  
    const currentStatus = normalizedStatus(claim?.internal_status);
    
    let statusOptions = Array.isArray(userRole === 'employee' ? employeeStatusOptions : allStatusOptions) 
      ? (userRole === 'employee' ? employeeStatusOptions : allStatusOptions) 
      : [];
  
    // For employees, if the current status is "Replaced/Credit Issued", only show this option
    if (userRole === 'employee' && currentStatus === 'Replaced/Credit Issued') {
      statusOptions = ['Replaced/Credit Issued'];
    } else if (userRole === 'employee') {
      // For employees, remove "Replaced/Credit Issued" from options if it's not the current status
      statusOptions = statusOptions.filter(status => normalizedStatus(status) !== 'Replaced/Credit Issued');
    }
  
    // Apply other existing filters (username checks, RC claim checks, etc.)
    const lowerUsername = (username || '').toLowerCase();
    if (!(lowerUsername === 'sanna' || lowerUsername === 'sana' || lowerUsername === 'jim') && !claim?.warranty_id) {
      statusOptions = statusOptions.filter(status => ['Pending', 'Declined', 'Closed'].includes(status));
    }
  
    if ((claimId || '').startsWith('RC') && !(lowerUsername === 'sanna' || lowerUsername === 'sana' || lowerUsername === 'jim')) {
      statusOptions = statusOptions.filter(status => normalizedStatus(status) !== 'Replaced/Credit Issued');
    }
  
    // Determine if the field should be read-only
    const isReadOnly = (userRole === 'employee' && currentStatus === 'Replaced/Credit Issued') ||
      (!(lowerUsername === 'sanna' || lowerUsername === 'sana' || lowerUsername === 'jim') && 
      ['repaired', 'replaced/credit issued', 'declined'].includes(currentStatus.toLowerCase()));
  
    return (
      <Form.Control
        as="select"
        value={currentStatus}
        onChange={(e) => handleInputChange("internal_status", e.target.value)}
        className="form-control"
        style={{ fontSize: '14px' }}
        disabled={isReadOnly}
      >
        {statusOptions.map((status) => (
          <option key={status} value={normalizedStatus(status)}>{normalizedStatus(status)}</option>
        ))}
      </Form.Control>
    );
  };








  return (
    <Container fluid className="p-4" style={{ background: "linear-gradient(135deg, #f5f7fa, #c3cfe2)", minHeight: "100vh" }}>
      {notification && <Alert variant="info">{notification}</Alert>}
      <Modal show={modal} onHide={toggleModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div style={{ fontSize: '50px', marginBottom: '20px' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#3498db"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-check-circle"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
              <polyline points="22 4 12 14.01 9 11.01"></polyline>
            </svg>
          </div>
          <h4>Hey, you have successfully paired a claim!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleConfirmPairing}>OK</Button>
        </Modal.Footer>
      </Modal>
      <Row className="mb-3">
        <Col md={{ span: 10, offset: 1 }}>
          <Card className="shadow-lg border-0" style={{ borderRadius: "15px" }}>
            <Card.Body style={{ background: "#f8f9fa", borderRadius: "15px" }}>
              <Row>
                <Col md={6}>
                  <h3 className="mb-4">
                    <b>
                      Details of
                      <span style={{ color: 'red', marginLeft: '10px' }}>
                        {claim.full_name}
                      </span>
                    </b>
                    <div style={{ textAlign: 'center', marginTop: '10px', fontWeight: 'bold' }}>
                      <span style={{ color: isServiceClaim ? '#ff41ed' : 'green' }}>
                        {isServiceClaim ? '*** This is a SERVICE CLAIM ***' : '*** This is an AWS CLAIM ***'}
                      </span>
 			{expirationDate && (
                        <div style={{ marginTop: '10px' }}>
                          <span style={{ color: 'blue' }}>
                            Expiration date is: {expirationDate}
                          </span>
                        </div>
                      )}
                    </div>
                  </h3>
                  {/* <Button variant="primary" onClick={toggleEditMode}>
                    {editMode ? <><FaSave /> Save Changes</> : <><FaEdit /> Update Manually</>}
                  </Button> */}




                  <Button variant="primary" onClick={toggleEditMode}>
                    {editMode ? <><FaSave /> Save Changes</> : <><FaEdit /> Update Manually</>}
                  </Button>
                  <StyledTable striped bordered hover responsive className="shadow-lg" style={{ borderRadius: "15px", overflow: "hidden" }}>
                    <tbody>
                      <tr>
                        <th>Status</th>
                        <td>{claim.internal_status}</td>
                      </tr>
                      <tr>
                        <th>Date Of Claim</th>
                        <td>{formatDate(claim.created_date_only)}</td>
                      </tr>
                      <tr>
                        <th>Product Purchase Date</th>
                        <td>{claim.purchase_date}</td>
                      </tr>
                      <tr>
                        <th>Store Number</th>
                        <td>{claim.store}</td>
                      </tr>
                      <tr>
                        <th>Brand</th>
                        <td>{toUpperCase(claim.brand)}</td>
                      </tr>
                      {/* <tr>
                        <th>Customer Name</th>
                        <td>
                          {editMode ? (
                            <>
                              <Form.Control
                                type="text"
                                placeholder="First Name"
                                value={claim.first_name || ''}
                                onChange={(e) => handleInputChange('first_name', e.target.value)}
                                className="form-control mb-2"
                              />
                              <Form.Control
                                type="text"
                                placeholder="Last Name"
                                value={claim.last_name || ''}
                                onChange={(e) => handleInputChange('last_name', e.target.value)}
                                className="form-control"
                              />
                            </>
                          ) : (
                            claim.full_name
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td>
                          {editMode ? (
                            <>
                              <Form.Control
                                type="text"
                                placeholder="Address"
                                value={claim.address || ''}
                                onChange={(e) => handleInputChange('address', e.target.value)}
                                className="form-control mb-2"
                              />
                              <Form.Control
                                type="text"
                                placeholder="City"
                                value={claim.city || ''}
                                onChange={(e) => handleInputChange('city', e.target.value)}
                                className="form-control mb-2"
                              />
                              <Form.Control
                                type="text"
                                placeholder="State"
                                value={claim.state || ''}
                                onChange={(e) => handleInputChange('state', e.target.value)}
                                className="form-control mb-2"
                              />
                              <Form.Control
                                type="text"
                                placeholder="Zip Code"
                                value={claim.zipcode || ''}
                                onChange={(e) => handleInputChange('zipcode', e.target.value)}
                                className="form-control"
                              />
                            </>
                          ) : (
                            `${claim.address}, ${claim.city}, ${claim.state}, ${claim.zipcode}`
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>
                          {editMode ? (
                            <Form.Control
                              type="text"
                              value={claim.email || ''}
                              onChange={(e) => handleInputChange('email', e.target.value)}
                              className="form-control"
                            />
                          ) : (
                            claim.email
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Phone Number</th>
                        <td>
                          {editMode ? (
                            <Form.Control
                              type="text"
                              value={claim.phone_number || ''}
                              onChange={(e) => handleInputChange('phone_number', e.target.value)}
                              className="form-control"
                            />
                          ) : (
                            <>
                              {claim.phone_number}
                              <Button
                                variant="outline-secondary"
                                size="sm"
                                onClick={() => handleCopyClick(claim.phone_number)}
                                style={{ marginLeft: '100px' }}
                              >
                                <FaCopy /> Copy Number
                              </Button>
                              {copyStatus && (
                                <span style={{ marginLeft: '10px', color: 'green' }}>{copyStatus}</span>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Customer Description</th>
                        <td>
                          {editMode ? (
                            <Form.Control
                              type="text"
                              value={claim.customer_description || ''}
                              onChange={(e) => handleInputChange('customer_description', e.target.value)}
                              className="form-control"
                            />
                          ) : (
                            claim.customer_description
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Transaction Number</th>
                        <td>{claim.transaction_number}</td>
                      </tr>
                      <tr>
                        <th>Tracking Number</th>
                        <td>
                          {editMode ? (
                            <Form.Control
                              type="text"
                              value={claim.tracking_number || ''}
                              onChange={(e) => handleInputChange('tracking_number', e.target.value)}
                              className="form-control"
                            />
                          ) : (
                            claim.tracking_number
                          )}
                        </td>
                      </tr>




		     <tr>
                        <th>Product Model Number</th>
                        <td>
                          {editMode ? (
                            <Form.Control
                              type="text"
                              value={pendingChanges.model_number !== undefined ? pendingChanges.model_number : claim.model_number || ''}
                              onChange={(e) => handleInputChange('model_number', e.target.value)}
                              className="form-control"
                            />
                          ) : (
                            claim.model_number
                          )}
                        </td>
                      </tr>


                     




                      <tr>
                        <th>Warranty Model Number</th>
                        <td>{claim.warranty_id}</td>
                      </tr>
                      <tr>
                        <th>Serial Number</th>
                        <td>
                          {editMode ? (
                            <Form.Control
                              type="text"
                              value={claim.serial_number || ''}
                              onChange={(e) => handleInputChange('serial_number', e.target.value)}
                              className="form-control"
                            />
                          ) : (
                            toUpperCase(claim.serial_number)
                          )}
                        </td>
                      </tr> */}






                      <tr>
                        <th>Customer Name</th>
                        <td>
                          {editMode ? (
                            <>
                              <Form.Control
                                type="text"
                                placeholder="First Name"
                                value={pendingChanges.first_name !== undefined ? pendingChanges.first_name : claim.first_name || ''}
                                onChange={(e) => handleInputChange('first_name', e.target.value)}
                                className="form-control mb-2"
                              />
                              <Form.Control
                                type="text"
                                placeholder="Last Name"
                                value={pendingChanges.last_name !== undefined ? pendingChanges.last_name : claim.last_name || ''}
                                onChange={(e) => handleInputChange('last_name', e.target.value)}
                                className="form-control"
                              />
                            </>
                          ) : (
                            claim.full_name
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td>
                          {editMode ? (
                            <>
                              <Form.Control
                                type="text"
                                placeholder="Address"
                                value={pendingChanges.address !== undefined ? pendingChanges.address : claim.address || ''}
                                onChange={(e) => handleInputChange('address', e.target.value)}
                                className="form-control mb-2"
                              />
                              <Form.Control
                                type="text"
                                placeholder="City"
                                value={pendingChanges.city !== undefined ? pendingChanges.city : claim.city || ''}
                                onChange={(e) => handleInputChange('city', e.target.value)}
                                className="form-control mb-2"
                              />
                              <Form.Control
                                type="text"
                                placeholder="State"
                                value={pendingChanges.state !== undefined ? pendingChanges.state : claim.state || ''}
                                onChange={(e) => handleInputChange('state', e.target.value)}
                                className="form-control mb-2"
                              />
                              <Form.Control
                                type="text"
                                placeholder="Zip Code"
                                value={pendingChanges.zipcode !== undefined ? pendingChanges.zipcode : claim.zipcode || ''}
                                onChange={(e) => handleInputChange('zipcode', e.target.value)}
                                className="form-control"
                              />
                            </>
                          ) : (
                            `${claim.address}, ${claim.city}, ${claim.state}, ${claim.zipcode}`
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>
                          {editMode ? (
                            <Form.Control
                              type="text"
                              value={pendingChanges.email !== undefined ? pendingChanges.email : claim.email || ''}
                              onChange={(e) => handleInputChange('email', e.target.value)}
                              className="form-control"
                            />
                          ) : (
                            claim.email
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Phone Number</th>
                        <td>
                          {editMode ? (
                            <Form.Control
                              type="text"
                              value={pendingChanges.phone_number !== undefined ? pendingChanges.phone_number : claim.phone_number || ''}
                              onChange={(e) => handleInputChange('phone_number', e.target.value)}
                              className="form-control"
                            />
                          ) : (
                            <>
                              {claim.phone_number}
                              <Button
                                variant="outline-secondary"
                                size="sm"
                                onClick={() => handleCopyClick(claim.phone_number)}
                                style={{ marginLeft: '100px' }}
                              >
                                <FaCopy /> Copy Number
                              </Button>
                              {copyStatus && (
                                <span style={{ marginLeft: '10px', color: 'green' }}>{copyStatus}</span>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Customer Description</th>
                        <td>
                          {editMode ? (
                            <Form.Control
                              type="text"
                              value={pendingChanges.customer_description !== undefined ? pendingChanges.customer_description : claim.customer_description || ''}
                              onChange={(e) => handleInputChange('customer_description', e.target.value)}
                              className="form-control"
                            />
                          ) : (
                            claim.customer_description
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Transaction Number</th>
                        <td>{claim.transaction_number}</td>
                      </tr>
                      <tr>
                        <th>Tracking Number</th>
                        <td>
                          {editMode ? (
                            <Form.Control
                              type="text"
                              value={pendingChanges.tracking_number !== undefined ? pendingChanges.tracking_number : claim.tracking_number || ''}
                              onChange={(e) => handleInputChange('tracking_number', e.target.value)}
                              className="form-control"
                            />
                          ) : (
                            claim.tracking_number
                          )}
                        </td>
                      </tr>


 <tr>
                        <th>Product Model Number</th>
                        <td>
                          {editMode ? (
                            <Form.Control
                              type="text"
                              value={pendingChanges.model_number !== undefined ? pendingChanges.model_number : claim.model_number || ''}
                              onChange={(e) => handleInputChange('model_number', e.target.value)}
                              className="form-control"
                            />
                          ) : (
                            claim.model_number
                          )}
                        </td>
                      </tr>





                      <tr>
                        <th>Serial Number</th>
                        <td>
                          {editMode ? (
                            <Form.Control
                              type="text"
                              value={pendingChanges.serial_number !== undefined ? pendingChanges.serial_number : claim.serial_number || ''}
                              onChange={(e) => handleInputChange('serial_number', e.target.value)}
                              className="form-control"
                            />
                          ) : (
                            toUpperCase(claim.serial_number)
                          )}
                        </td>
                      </tr>


                      <tr>
                        <th>Product Price</th>
                        <td>{claim.price_product}</td>
                      </tr>
                      <tr>
                        <th>Warranty Price</th>
                        <td>{claim.price_warranty}</td>
                      </tr>
                      <tr>
                        <th>Warranty Model number</th>
                        <td>{claim.warranty_id}</td>
                      </tr>
                      <tr>
                        <th>Front Picture</th>
                        <td>
                          {claim.front_photo ? (
                            <a href={claim.front_photo} target="_blank" rel="noopener noreferrer">
                              <img src={claim.front_photo} alt="Front" style={{ width: '100px', height: 'auto' }} />
                            </a>
                          ) : (
                            'No photo available'
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Model/Serial Tag</th>
                        <td>
                          {claim.back_photo ? (
                            <a href={claim.back_photo} target="_blank" rel="noopener noreferrer">
                              <img src={claim.back_photo} alt="Back" style={{ width: '100px', height: 'auto' }} />
                            </a>
                          ) : (
                            'No photo available'
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </StyledTable>
                </Col>
                <Col md={6}>
                  <h4 className="mb-4"><b>Edit Notes</b></h4>
                  <StyledTable striped bordered hover responsive className="shadow-lg" style={{ borderRadius: "15px", overflow: "hidden" }}>
                    <thead>
                      <tr>
                        <th>Notes for Customer</th>
                        <th>Employee Notes</th>
                        <th>Money Spent</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <EditableCell
                            id="notesForCustomer"
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            onBlur={(e) => handleInputChange('notesForCustomer', e.target.textContent)}
                            className="form-control"
                          >{notesForCustomer}</EditableCell>
                        </td>
                        <td>
                          <EditableCell
                            id="employeeNotes"
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            onBlur={(e) => handleInputChange('employeeNotes', e.target.textContent)}
                            className="form-control"
                          >{employeeNotes}</EditableCell>
                        </td>
                        <TableCell>
                          <EditableCell
                            id="moneySpent"
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            onBlur={(e) => handleInputChange("money_spent", e.target.textContent === '' ? '' : parseFloat(e.target.textContent))}
                            className="form-control"
                          >{moneySpent !== '' ? moneySpent : ''}</EditableCell>
                        </TableCell>
                        <td>
                          {renderStatusField()}
                        </td>
                        <td>
                          <Button style={{ marginTop: '18px', marginLeft: '10px' }} variant="primary" onClick={handleSubmitAll}>Submit</Button>
                          <Button style={{ marginTop: '18px', marginLeft: '10px' }} variant="success" onClick={handleSubmitWithEmail}>Submit with Email</Button>
                          <Button style={{ marginTop: '18px', marginLeft: '10px' }} variant="info" onClick={handleSchedule}>Schedule</Button>
                        </td>
                      </tr>
                    </tbody>
                  </StyledTable>
                  <h4 className="mb-4">
                    <b>Historical Details of <span style={{ color: 'red' }}>{claim.full_name}</span></b>
                  </h4>
                  <StyledTable striped bordered hover responsive className="shadow-lg" style={{ borderRadius: "15px", overflow: "hidden" }}>
                    <thead>
                      <tr>
                        <th>Notes for Customers</th>
                        <th>Employee Notes</th>
                        <th>Money Spent</th>
                        <th>Update date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {historicalData.slice(0, showAllRows ? historicalData.length : 5).map((record, index) => (
                        <tr key={index}>
                          <td>{record.notes_for_customer}</td>
                          <td>{record.employee_description}</td>
                          <td>{record.money_spent}</td>
                          <td>{record.history_date}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="4" style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>
                          Total Money Spent: ${totalMoneySpent.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </StyledTable>
                  {historicalData.length > 5 && (
                    <div className="text-right">
                      <Button variant="link" onClick={toggleShowAllRows}>
                        {showAllRows ? 'Show Less' : 'Show More'}
                      </Button>
                    </div>
                  )}
                  <h4 className="mb-4"><b>Upload Image and Update Status</b></h4>
                  <StyledTable striped bordered hover responsive className="shadow-lg" style={{ borderRadius: "15px", overflow: "hidden" }}>
                    <thead>
                      <tr>
                        <th>Upload Image</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="input-group">
                            <Form.Control
                              type="file"
                              style={{ maxWidth: '300px' }}
                              onChange={handleFileChange}
                            />
                          </div>
                        </td>
                        <td>
                          {renderStatusField()}
                        </td>
                        <td>
                          <Button
                            variant="primary"
                            onClick={handleSubmitStatus}
                            disabled={temporaryStatusChanges.isReadOnly}
                          >
                            Submit
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </StyledTable>
                  <Button variant="primary" onClick={uploadFile}>Upload Picture</Button>
                  <h4 className="mt-4 mb-3"><b>Claim Images</b></h4>
                  <StyledTable striped bordered hover responsive className="shadow-lg" style={{ borderRadius: "15px", overflow: "hidden" }}>
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Upload Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {repairPictures.map((picture, index) => (
                        <tr key={index}>
                          <td>
                            <img
                              src={picture.image_url}
                              alt={`Claim ${index + 1}`}
                              style={{ width: '100px', height: 'auto', cursor: 'pointer' }}
                              onClick={() => handleImageClick(picture.image_url)}
                            />
                          </td>
                          <td>{picture.upload_date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </StyledTable>
                  <Modal show={showImageModal} onHide={() => setShowImageModal(false)} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>Image Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <img src={selectedImage} alt="Preview" style={{ width: '100%', height: 'auto' }} />
                    </Modal.Body>
                  </Modal>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {!shouldHideSuggestedPairs && (
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <Card className="shadow-lg border-0" style={{ borderRadius: "15px", marginTop: "15px" }}>
              <Card.Body style={{ background: "#f8f9fa", borderRadius: "15px" }}>
                <h2 className="text-center mt-4" style={{ color: "#343a40" }}>Suggested Pairs</h2>
                <div style={{ margin: '0 -15px', overflowX: 'auto' }}>
                  <div style={{ margin: '0 -15px', overflowX: 'auto' }}>
                    {isLoadingSuggestedPairs ? ( // Show spinner if data is loading
                      <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                        <p>Loading suggested pairs...</p>
                      </div>
                    ) : (
                      <StyledTable striped bordered hover responsive className="shadow-lg" style={{ borderRadius: "15px", overflow: "hidden" }}>
                        <thead style={{ backgroundColor: "#343a40", color: "white" }}>
                       <tr>
                        <th>Warranty Sell Date</th>
                        <th>Product Sell Date</th>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Phone Number</th>
                        <th>Transaction Number</th>
                        <th>S Trans Number</th>
                        <th>Brand</th>
                        <th>Model Number</th>
                        <th>QTY</th>
                        <th>Warranty Type</th>
                        <th>Warranty Expiration</th>
                        <th>Warranty Price</th>
                        <th>Product Price</th>
                        <th>Store Location</th>
                        <th>Pair</th>
                      </tr>
                    </thead>
                    <tbody>
                      {suggestedPairs.map((pair, index) => (
                        <tr key={index}>
                          <td>{formatDate(pair.date_sale)}</td>
                          <td>{formatDate(pair.s_date_sold)}</td>
                          <td>{pair.name}</td>
                          <td>{pair.address}</td>
                          <td>{pair.phone_number}</td>
                          <td>{pair.transaction_number}</td>
                          <td>{pair.transaction_special}</td>
                          <td>{pair.brand}</td>
                          <td><strong>{pair.model_number}</strong></td>
                          <td>{pair.Quantity}</td>
                          <td><strong>{pair.warranty_type}</strong></td>
                          <td><strong>{formatDate(pair.warranty_expiry_date)}</strong></td>
                          <td>{pair.warranty_price}</td>
                          <td>{pair.product_price}</td>
                          <td>{pair.store_number}</td>
                          <td>
                            <Button onClick={() => handlePairButtonClick(pair)}>Pair</Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </StyledTable>
		 )}
		</div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <Card className="shadow-lg border-0" style={{ borderRadius: "15px", marginTop: "15px" }}>
            <Card.Body style={{ background: "#f8f9fa", borderRadius: "15px" }}>
              <h4 className="mb-4">
                <b>Claim History of <span style={{ color: 'red' }}>{claim.full_name}</span></b>
              </h4>
              <StyledTable striped bordered hover responsive className="shadow-lg" style={{ borderRadius: "15px", overflow: "hidden" }}>
                <thead>
                  <tr>
                    <th style={{ backgroundColor: 'yellow', color: 'black' }}>Claim id</th>
                    <th style={{ backgroundColor: 'yellow', color: 'black' }}>Name</th>
                    <th style={{ backgroundColor: 'yellow', color: 'black' }}>Address</th>
                    <th style={{ backgroundColor: 'yellow', color: 'black' }}>Request Date</th>
                    <th style={{ backgroundColor: 'yellow', color: 'black' }}>Brand Name</th>
                    <th style={{ backgroundColor: 'yellow', color: 'black' }}>Model Number</th>
                    <th style={{ backgroundColor: 'yellow', color: 'black' }}>Customer Description</th>
                    <th style={{ backgroundColor: 'yellow', color: 'black' }}>Employee Notes</th>
                    <th style={{ backgroundColor: 'yellow', color: 'black' }}>Status</th>
                    <th style={{ backgroundColor: 'yellow', color: 'black' }}>Transaction Number</th>
                  </tr>
                </thead>
                <tbody>
                  {claimHistoryData.map((record, index) => (
                    <tr key={index}>
                      <td>
                        <strong
                          onClick={() => handleClaimClick(record.claimid)}
                          style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                        >
                          {record.claimid}
                        </strong>
                      </td>
                      <td>{`${record.first_name} ${record.last_name}`}</td>
                      <td>{`${record.address}, ${record.city}, ${record.state}, ${record.zipcode}`}</td>
                      <td><strong>{formatDate(record.request_date)}</strong></td>
                      <td>{record.brand_name}</td>
                      <td><strong>{toUpperCase(record.model_number)}</strong></td>
                      <td>{record.customer_description}</td>
                      <td>{record.employee_description}</td>
                      <td><strong>{record.internal_status}</strong></td>
                      <td>{record.transaction_number}</td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </Card.Body>
          </Card>
        </Col>
      </Row>



 <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <CustomerDetails2 username={claim.full_name} />
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <CustomerDetails3 username={claim.full_name} />
        </Col>
 </Row>



      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <Card className="shadow-lg border-0" style={{ borderRadius: "15px", marginTop: "15px" }}>
            <Card.Body style={{ background: "#f8f9fa", borderRadius: "15px" }}>
              <h4><b>Message History of {claim.full_name}</b></h4>
              <StyledTable striped bordered hover responsive className="shadow-lg" style={{ borderRadius: "15px", overflow: "hidden" }}>
                <thead>
                  <tr>
                    <th style={{ backgroundColor: 'red', color: 'black' }}>Claim id</th>
                    <th style={{ backgroundColor: 'red', color: 'black' }}>Message</th>
                    <th style={{ backgroundColor: 'red', color: 'black' }}>Date of message</th>
                  </tr>
                </thead>
                <tbody>
                  {messageHistory.map((record, index) => (
                    <tr key={index}>
                      <td>{record.shortcut}</td>
                      <td>{record.message}</td>
                      <td>{new Date(record.date_insert).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })} {new Date(record.date_insert).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(CustomerDetails);

