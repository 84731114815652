import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Navbar from "./component/Navbar";
// import Footer from "./component/Footer";
import CustomerRequests from "./component/CustomerRequests";
import SearchWarranty from "./component/SearchWarranty";
import CustomerDetails from "./component/Customer_details";
import PairButton from "./component/PairButton";
import Dispatch from "./component/Dispatch";
import AdminLogin from "./component/AdminLogin";
import { ExpirationDateProvider } from "./component/ExpirationDateContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import Reports from "./component/Reports";




function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [userRole, setUserRole] = useState("");


  const handleAdminLogin = (username, role) => {
    setIsLoggedIn(true);
    setUsername(username);
    setUserRole(role); // Store the role in the state
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("username", username);
    localStorage.setItem("role", role); // Store the role in localStorage
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername("");

    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("username");

  };
// Update the effect to retrieve the user role from localStorage
useEffect(() => {
  const loggedIn = localStorage.getItem("isLoggedIn");
  const storedUsername = localStorage.getItem("username");
  const storedRole = localStorage.getItem("role");

  if (loggedIn === "true" && storedUsername) {
    setIsLoggedIn(true);
    setUsername(storedUsername);
    setUserRole(storedRole); // Retrieve the role from localStorage
  }
}, []);

  return (
    <ExpirationDateProvider>
      <Router>
        <div className="app-container">
          <Navbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} username={username} />
          <Switch>
  <Route
    exact
    path="/"
    render={() =>
      isLoggedIn ? (
        <CustomerRequests username={username} userRole={userRole} />
      ) : (
        <AdminLogin onAdminLogin={handleAdminLogin} />
      )
    }
  />
  <PrivateRoute
    path="/customer_details/:claimId"
    component={CustomerDetails}
    isLoggedIn={isLoggedIn}
    username={username}
    userRole={userRole}
  />
  <PrivateRoute
    path="/dispatch_track_schedule/:claimId"
    component={Dispatch}
    isLoggedIn={isLoggedIn}
    userRole={userRole}
  />
  <PrivateRoute
    path="/PairButton/:claimId"
    component={PairButton}
    isLoggedIn={isLoggedIn}
    userRole={userRole}
  />
  <PrivateRoute
    path="/SearchWarranty"
    component={SearchWarranty}
    isLoggedIn={isLoggedIn}
    userRole={userRole}
  />
  <PrivateRoute
    path="/customer_requests"
    component={CustomerRequests}
    isLoggedIn={isLoggedIn}
    username={username}
    userRole={userRole}
  />
  <PrivateRoute
    path="/generate_reports"
    component={Reports}
    isLoggedIn={isLoggedIn}
    username={username}
    userRole={userRole}
  />
  <Redirect to="/" />
  </Switch>

          {/* <Footer /> */}
        </div>
      </Router>
    </ExpirationDateProvider>
  );
}

const PrivateRoute = ({ component: Component, isLoggedIn, username,userRole, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem("isLoggedIn") === "true" ? (
        <Component {...props} username={localStorage.getItem("username")} userRole={localStorage.getItem("role")} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export default App;




