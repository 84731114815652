import React, { useEffect, useState } from "react";
import { Table, Card, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";
import { useParams } from "react-router-dom";

// Styled Table
const StyledTable = styled(Table)`
  margin-bottom: 30px;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
`;

const CardContainer = styled(Card)`
  border-radius: 15px;
  margin-top: 10px;
  width: 100%;
`;

const CardBodyContainer = styled(Card.Body)`
  background: #f8f9fa;
  border-radius: 15px;
  padding: 20px;
`;

const TableRow = styled.tr`
  font-size: 14px;
`;

const TableHeader = styled.th`
  background-color: yellow;
  color: black;
  font-size: 14px;
  text-align: center;
`;

const TableCell = styled.td`
  text-align: center;
  font-size: 14px;
`;

const CustomStyledTable = styled(StyledTable)`
  background-color: white; // Pure white background for the entire table
  color: black; // Black text color for all table cells and headers
  border: 1px solid #dee2e6;

  th,
  td {
    color: black; // Black text for both header and cells
    background-color: white; // White background for both header and cells
    text-align: center; // Center align text
    border: 1px solid #dee2e6; // Add border for better readability
    vertical-align: middle; // Ensure vertical alignment in case of multiline text
  }

  th {
    font-weight: bold;
    border-bottom: 2px solid #dee2e6; // Add a stronger border for the header
  }

  tbody tr:nth-of-type(even) {
    background-color: #f9f9f9; // Subtle background for alternating rows
  }
`;

const DispatchOrderDetails = () => {
  const { claimId } = useParams(); // Extract claimId from the URL
  const [orderData, setOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  // Fetch Order Data
  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/dispatch_order_details/`,
          { params: { claimId } }
        );
        setOrderData(response.data.orders);
      } catch (error) {
        console.error("Error fetching order data:", error.message);
        if (error.response && error.response.status === 404) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage("An error occurred while fetching data.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderData();
  }, [claimId]);

  // Split driver notes by |, remove time and timezone part
  const formatDriverNotes = (notes) => {
    if (!notes) return [];
    return notes.split("|").map((note) => {
      const match = note.trim().match(/^(\d{4}-\d{2}-\d{2}) .*? - (.*)$/);
      if (match) {
        return `${match[1]} - ${match[2].trim()}`;
      }
      return note.trim();
    });
  };

  return (
    <Row>
      <Col>
        <CardContainer className="shadow-lg border-0">
          <CardBodyContainer>
            <h4 className="mb-4" style={{ color: "black" }}>
              <b>Customer Data from Dispatch Track</b>
            </h4>
            {errorMessage ? (
              <p style={{ color: "red", textAlign: "center" }}>
                {errorMessage}
              </p>
            ) : isLoading ? (
              <p style={{ textAlign: "center" }}>Loading...</p>
            ) : orderData.length > 0 ? (
              <CustomStyledTable
                striped
                bordered
                hover
                responsive
                className="shadow-lg"
              >
                <thead>
                  <TableRow>
                    <TableHeader>Order ID</TableHeader>
                    <TableHeader>Customer Name</TableHeader>
                    <TableHeader>Status</TableHeader>
                    <TableHeader>Service By</TableHeader>
                    <TableHeader>Driver Notes</TableHeader>
                  </TableRow>
                </thead>
                <tbody>
                  {orderData.map((order, index) => (
                    <TableRow key={index}>
                      <TableCell>{order.order_id}</TableCell>
                      <TableCell>{order.customer_name}</TableCell>
                      <TableCell>{order.status}</TableCell>
                      <TableCell>{order.service_by}</TableCell>
                      <TableCell>
                        <CustomStyledTable>
                          <tbody>
                            {formatDriverNotes(order.driver_notes).map(
                              (note, i) => (
                                <TableRow key={i}>
                                  <TableCell>{note}</TableCell>
                                </TableRow>
                              )
                            )}
                          </tbody>
                        </CustomStyledTable>
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </CustomStyledTable>
            ) : (
              <p style={{ textAlign: "center" }}>
                No customer data available.
              </p>
            )}
          </CardBodyContainer>
        </CardContainer>
      </Col>
    </Row>
  );
};

export default DispatchOrderDetails;
